.animated-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa; 
    font-family: 'Arial', sans-serif;
    padding: 0 20px;
  }
  
  .fade-in-out-text {
    font-size: 3rem;
    line-height: 4.5rem;
    color: #45a994 ;
    text-align: center;
    font-weight: bold; 
    opacity: 0; 
    animation: fadeInOut 4s ease-in-out forwards; 
    transition: opacity 1s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  @media (max-width: 768px) {
    .fade-in-out-text {
      font-size: 1.5rem; 
      padding: 0 10px;
      line-height: 2rem;
    }
}

  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    10%, 50% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.1); 
    }
  }
  