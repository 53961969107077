


/* chatbot */

.chatbot-content {
    position: fixed;
    bottom: 80px;
    right: 5rem;
    width: 30%;
    height: 35rem;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chatFooter{
    height: 1rem;
}

/* Toggle button styling */
.chatbot-toggle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #45a994;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1001;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 24px;
}

.chatbot-toggle-button:hover {
    background-color: #3c917d;
    transform: scale(1.1);
}


.messageAction {
    display: flex;
    gap: 2px;
    /* margin-top: -8px; */
    justify-content: start;
    flex-direction: row-reverse;
}
.bot .messageAction {
    justify-content: end;
    flex-direction: row;
}
.messageAction button,
.AddAction button {
    height: 20px;
    width: 20px;
    padding: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageAction button ion-icon,
.AddAction button ion-icon {
    font-size: 15px;
    margin: 0;
  }

  .message-item .bubble {
    min-width: 100px;
  }

  .message-divider .backgroundProcess {
    background-color: rgb(216, 213, 207);
    width: fit-content;
    padding: 0 5px;
    border-radius: 5px;
    display: block;
    margin: auto;
    color: black;
  }

  
.message-item.bot {
    justify-content: flex-end;
    margin-right: 0;
    margin-left: 60px;
}
.message-item.bot .bubble {
    background: #24265d;
    color: #FFF;
    border-radius: 16px 16px 0 16px;
}

.message-item .AddAction {
    margin-bottom: -8px;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: end;
}

.message-item.bot .AddAction {
    justify-content: start;
}



/* .chatbot-content .chatFooter{
    height: 3rem;
} */
.chatbot-content .message-box{
    height: 30rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
}
/* style={{height: '30rem', overflowY: 'auto'}} */

@media(max-width: 768px){
    .chatbot-content {
        right: 2rem;
        width: 86%;
        height: 41rem !important;
        display: block;
    }
    .chatbot-content .relative{
        height: 100%;
        transform: translateY(50px);
    }
    .chatbot-content .message-box{
        height: 36rem !important;
    }
    .chatbot-content .chatFooter{
        transform: translateY(50px);
    }
}
